import React from "react";
export default () => (
  <div className="hero-header">
    <div className="primary-content">
      <p>
        I'm a British software engineer living in Tokyo, Japan. You can also
        find me on{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/antonytdotcom"
        >
          Facebook
        </a>
        ,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/antony_tran"
        >
          Instagram
        </a>{" "}
        and{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/antony-tran-swe"
        >
          Linkedin
        </a>
        .
      </p>
    </div>
  </div>
);
